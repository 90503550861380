import axios, {AxiosResponse, AxiosError } from 'axios';
import mixins from '@/mixins';

const fetchTableRows = ({ commit, dispatch }, payload) => {
    return new Promise(async (resolve, reject) => {
        try {
            await mixins.methods.setQueryParams(payload || {});
            const perpage = localStorage.getItem('customers_per_page') || 50;
            
            let params = new URLSearchParams(location.search);
            params.set('size', perpage);
            params.set('sort', 'lastName');
            params = params.toString();

            mixins.methods.loader();

            const { data } = await axios.get(`/xhr/dashboard/customers?${params}`);
            data.perPage = perpage;
            commit('setTable', data);

            mixins.methods.loader(false);
            resolve(true);
        } catch (err) {
            reject(dispatch('toast/showToast', null, { root: true }));
        }
    });
};

const createTableRow = ({ commit, dispatch }, payload) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await axios.post('/xhr/dashboard/customers', payload);
            const { fullName, company, firstName, lastName } = data;

            mixins.methods.resetUri();
            commit('resetSearch');
            mixins.methods.setQueryParams({ fullName, company });
            commit('setSearch', { fullName, company });
            // Create folder after successfully creating a customer.
            dispatch('createFolder', { firstName, lastName, company });
            resolve(true);
        } catch (err) {
            reject(dispatch('toast/showToast', null, { root: true }));
        }
    });
};

const deleteTableRow = ({ dispatch }, { id }) => {
    return new Promise(async (resolve, reject) => {
        try {
            await axios.delete(`/xhr/dashboard/customers/${id}`);
            resolve(true);
        } catch (err) {
            reject(dispatch('toast/showToast', null, { root: true }));
        }
    });
};

const updateTableRow = ({ dispatch }, payload) => {
    return new Promise(async (resolve, reject) => {
        try {
            const id = payload.id;
            await axios.put(`/xhr/dashboard/customers/${id}`, payload);
            resolve(true);
        } catch (err) {
            reject(dispatch('toast/showToast', null, { root: true }));
        }
    });
};

const customersList = ({ commit, dispatch }, payload = { fullName: '' }) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await axios.get(`/xhr/dashboard/customers?size=50&sort=lastName&include=fullName&include=company&include=address&fullName=${payload.fullName}`);
            commit('setCustomerList', data.rows);
            resolve(data.rows.length > 0);
        } catch (err) {
            reject(dispatch('toast/showToast', null, { root: true }));
        }
    });
};

const readTableRow = ({ dispatch }, { customerId }) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await axios.get(`/xhr/dashboard/customers/${customerId}`);
            resolve(data);
        } catch (err) {
            reject(dispatch('toast/showToast', null, { root: true }));
        }
    });
};

const openFolder = ({ dispatch }, { firstName, lastName, company }) => {
    return new Promise(async (resolve, reject) => {
        try {

            let type;
            let name;

            if(company) {
                type = 'business';
                name = encodeURIComponent(company)
            } else {
                type = 'personal'
                name = encodeURIComponent([lastName, firstName].join(', '));
            }
         
            const url = `http://localhost:3001/open-folder?token=K2mQGA4stYKVuiuHEg246jlI4kqTblh6SiUKuT6SUZcErVzhYdCNoY30c9Vtpy8d&name=${name}&type=${type}`;
            const res = await axios.get(url, { withCredentials: false })
         
            if (res.status !== 200) {
               dispatch('toast/showToast', `Folder <span class="font-bold" style="word-break: break-all">${decodeURIComponent(name)}</span> does not exist!`, { root: true });
            } 

            resolve(true);
        } catch (err) {
            let msg;
            if(err.status === 404) {
                msg = 'Failed to open folder. Please check if the <span class="font-bold">Rovner Apps</span> program is running in your system tray.';
            }
            reject(dispatch('toast/showToast', msg, { root: true }));
        }
    });
};

const createFolder = ({ dispatch }, {firstName, lastName, company}) => {
    return new Promise(async (resolve, reject) => {
        try {
            let type;
            let name;

            if(company) {
                type = 'business';
                name = encodeURIComponent(company)
            } else {
                type = 'personal'
                name = encodeURIComponent([lastName, firstName].join(', '));
            }

            const url = `http://localhost:3001/create-folder?token=K2mQGA4stYKVuiuHEg246jlI4kqTblh6SiUKuT6SUZcErVzhYdCNoY30c9Vtpy8d&name=${name}&type=${type}`;
            const res =  await axios.get(url, { withCredentials: false });
            resolve(true);
        } catch (err) {
            let msg;
            if(err.status === 404) {
                msg = 'Failed to create folder. Please check if the <span class="font-bold">Rovner Apps</span> program is running in your system tray.';
            }
            reject(dispatch('toast/showToast', msg, { root: true }));
        }
    });
};

export default {
    fetchTableRows,
    createTableRow,
    deleteTableRow,
    updateTableRow,
    readTableRow,

    openFolder,
    createFolder,
    customersList,
};
