import mixins from '@/mixins'; 
import index from './index';

const setTable = (state, { rows, count, page, perPage, premiumTotal, oldPremiumTotal, premiumAdjustedTotal, dollarAdjustedTotal }) => {
   rows = rows.map(item => {
    item.cancelled = mixins.methods.utcToLocal(item.cancelled);
    item.effective = mixins.methods.utcToLocal(item.effective);
    item.expires = mixins.methods.utcToLocal(item.expires);
    return item;
  });

  state.table.rows = rows;
  state.table.pagination = mixins.methods.formatPagination({count, page, perPage});
  state.table.premiumTotal = premiumTotal;
  state.table.oldPremiumTotal = oldPremiumTotal;
  state.table.premiumAdjustedTotal = premiumAdjustedTotal;
  state.table.dollarAdjustedTotal = dollarAdjustedTotal;
};

const setSearch = (state, payload) => {
  state.table.search = { ...state.table.search, ...payload };
};

const resetSearch = (state, payload) => {
  state.table.search = {};
};

const showEdit = (state) => {
  state.modal.component = 'Edit';
};


const showAdd = (state, payload) => {
   state.modal.component = 'Add';
};


const showDelete = (state) => {
   state.modal.component = 'Delete';
};


const hideModal = (state, payload) => {
  state.modal.details = index.state().modal.details;
  state.modal.component = '';
};


const setDetails = (state, payload) => {
   state.modal.details = {...state.modal.details, ...payload}
};

export default {
  setTable,
  setSearch,
  resetSearch,
  setDetails,
  showEdit,
  showDelete,
  hideModal,
  showAdd
};
