import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import objectSupport from 'dayjs/plugin/objectSupport';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(objectSupport);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(timezone);

// Make this script available even outside vue mixins
const toMmddyyyy = (date) => {
    if (/^\d{1,2}(\/)\d{1,2}(\/)\d{4}$/.test(date)) {
        const [m, d, yyyy] = date.split('/');
        date = [m.padStart(2, '0'), d.padStart(2, '0'), yyyy].join('/');
    }
    return date;
};

export default {
    methods: {
        loader(show = true) {
            const el = document.querySelector('#preloader');
            if (show) {
                el.style.display = 'flex';
            } else {
                el.style.display = 'none';
            }
        },
        xOrCheck(val, pic = 'row-x.svg') {
            // default is x icon;
            if (val === null) {
                return;
            }

            if (val) {
                pic = 'row-check.svg';
            }

            return `<img src="${require(`./assets/${pic}`)}"/>`;
        },
        hash() {
            return Math.random().toString(36).substring(2, 7);
        },
        hideModals() {
            this.$store.dispatch('hideModals');
        },
        addPercent(val) {
            if (val && val !== 0) {
                return val + '%';
            }
        },
        getImgUrl(pic) {
            return require('./assets/' + pic);
        },
       
        toMoney(v) {
            if (v) {
                // Without dollar sign
                return v.toLocaleString('en-US', {minimumFractionDigits: 2});
            }
        },
        moneyToNo(v) {
            if (v) {
                return +v.toString().replace(/[^0-9.]/g, '');
            }
            return null;
        },
        resetUri() {
            window.history.replaceState({}, document.title, window.location.pathname);
        },
        toDollar(v) {
            // With dollar sign
            if (v) {
                return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(v);
            }
        },
        formatPagination({count, page, perPage}) {
            const pagination = {
                per_page: parseInt(perPage),
                to: page + 1,
                last_page: Math.ceil(count / perPage) - 1,
                current_page: page,
                from: page - 1,
                total: count,
            };

            return pagination;
        },
        setQueryParams(obj) {
            if (typeof window !== 'undefined') {
                let params = new URLSearchParams(location.search);
                if (typeof obj === 'object') {
                    for (const key of Object.keys(obj)) {
                        if (obj[key] !== undefined && obj[key] !== null && obj[key].toString().trim() !== '') {
                            params.set(key, obj[key]);
                        } else {
                            params.delete(key);
                        }
                    }
                    params = params.toString();
                    params = params ? `?${params}` : location.pathname;
                    return history.replaceState(null, null, params);
                }
            }
        },

        utcToLocal(date) {
            if (date) {
               // allow only valid format
                if(dayjs(date).isValid()) {
                    // Don't need to add utc(). Date from server does have timezone
                      return dayjs(date).local().format('M/D/YYYY');
                } else {
                      console.log('invalid date format:', date)
                      return null;
                }
            }
        },
        addDuration(date, length, duration = 'days') {
            date = toMmddyyyy(date); 
            // local days m/d/yyyy and add days
            if (date && dayjs(date).isValid()) {
                const res = dayjs(date).add(length, duration);

                if (duration === 'months') {
                    const prev = dayjs(date).date();
                    const now = res.date();

                    if (prev !== now) {
                        const daysDiff = prev - now;
                        return res.add(daysDiff, 'days').format('M/D/YYYY');
                    }
                }
                return res.format('M/D/YYYY');
            }
            return null;
        },
        toMmddyyyy(date) {
          return toMmddyyyy(date)
        },
         addDays(date, days = 1) {
            date = toMmddyyyy(date); 

            // local days m/d/yyyy and add days
            if (date && dayjs(date).isValid()) {
                return dayjs(date).add(days, 'days').format('M/D/YYYY');
            }
            return null;
        },
        addOneDay(date) {
            date = toMmddyyyy(date); 

            if (date && dayjs(date).isValid()) {
                return dayjs(date).add(1, 'days').format('M/D/YYYY');
            }
            return null;
        },
        addOneYear(date) {
            date = toMmddyyyy(date); 

            // !/\d\d?\/\d\d?\/\d{4}$/g.test(date)
            // TO DO : Convert manual convert to dayJs
            if (date && dayjs(date).isValid()) {
                return dayjs(date).add(1, 'year').format('M/D/YYYY');
            }
            return null;
        },
         // validDate is taken
        testDate(date, format = 'M/D/YYYY') {

            if (!date) {
                return false;
            }

            if (format === 'YYYY-M-D' && /^\d{4}(\/)\d{1,2}(\/)\d{1,2}$/.test(date)) {
                const [yyyy, m, d] = date.split('-');
                date = [parseInt(m), parseInt(d), yyyy].join('/');
            }

            if (format === 'M/D/YYYY' && /^\d{1,2}(\/)\d{1,2}(\/)\d{4}$/.test(date)) {
                const [m, d, yyyy] = date.split('/');
                date = [parseInt(m), parseInt(d), yyyy].join('/');
            }

            // days(date, format, [Boolean]) - Boolean strict validation
            if (dayjs(date, format, true).isValid()) {
                return true;
            } else {
                return false;
            }
        },
         addtimezone(date) {
            // valid formats M[/|-]D[/|-]YYYY, MM[/|-]DD[/|-]YYY
            if (date && date.match(/^\d{1,2}(\/|-)\d{1,2}(\/|-)\d{4}$/)) {
                // Get local timezone
                const timezone = dayjs.tz.guess();
                let splitOf = '/'

                if(date.includes('-')) {
                    splitOf = '-'
                }

                let [mm, dd, yyyy] = date.split(splitOf);

                yyyy = parseInt(yyyy);
                dd = parseInt(dd);
                mm = parseInt(mm);

                return dayjs({year: yyyy, month: mm - 1, day: dd})
                    .tz(timezone)
                    .toISOString();
            } else {
                // console.log('Null or invalid format')
            }
            return null;
        },

        daysDiffAllowNegative(date1, date2) {
            date1 = toMmddyyyy(date1); 
            date2 = toMmddyyyy(date2); 

            date1 = dayjs(date1);
            date2 = dayjs(date2);
            return parseInt(dayjs.duration(date1.diff(date2)).asDays());
        },

        daysDiff(date1, date2) {
            date1 = toMmddyyyy(date1); 
            date2 = toMmddyyyy(date2); 

            date1 = dayjs(date1);
            date2 = dayjs(date2);
            return Math.abs(parseInt(dayjs.duration(date1.diff(date2)).asDays()));
        },
        monthsDiff(date1, date2) {
            date1 = toMmddyyyy(date1); 
            date2 = toMmddyyyy(date2); 

            date1 = dayjs(date1);
            date2 = dayjs(date2);
            return Math.abs(parseInt(dayjs.duration(date1.diff(date2)).asMonths()));
        },
    },
};
