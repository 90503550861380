import mixins from '@/mixins';
import index from './index';

const setTable = (state, { rows, count, page, perPage }) => {
   state.table.rows = rows;
   state.table.pagination = mixins.methods.formatPagination({count, page, perPage});
};

const setSearch = (state, payload) => {
  state.table.search = { ...state.table.search, ...payload };
};

const resetSearch = (state, payload) => {
  state.table.search = {};
};

const showAdd = (state) => {
  state.modal.details = index.state().modal.details
  state.modal.component = 'Add';
};

const setDeleteWarning = (state, payload) => {
 state.modal.warning = payload
};

const showPolicyAdd = (state) => {
  state.modal.details = index.state().modal.details
  state.modal.component = 'PolicyAdd';
};

const showEdit = (state, payload) => {
  payload = JSON.parse(JSON.stringify(payload));
  state.modal.details = payload;
  state.modal.component = 'Edit';
};

const showDelete = (state, payload) => {
   state.modal.details = payload;
   state.modal.component = 'Delete';
};

const hideModal = (state, payload) => {
  state.modal.details = index.state().modal.details
  state.modal.component = '';
  state.modal.warning = '';
};

const setCustomerList = (state, payload) => {
  state.customerList = payload
};

const setDetails = (state, payload) => {
  state.modal.details = {...state.modal.details, ...payload}
};

const setActiveRowId = (state, payload) => {
  state.activeRowId = payload;
};

const setTableScroll = (state, payload) => {
  state.tableScroll = payload;
};


export default {
  setTable,
  setSearch,
  resetSearch,
  setDetails,
  showAdd,
  showEdit,
  showDelete,
  hideModal,
  showPolicyAdd,
  setDeleteWarning,
  setCustomerList,
  setActiveRowId,
  setTableScroll
};
