import mixins from '@/mixins';
import index from './index';

const setTable = (state, { rows, count, page, perPage }) => {
    rows = rows.map((item) => {
        item.cancelled = mixins.methods.utcToLocal(item.cancelled);
        item.effective = mixins.methods.utcToLocal(item.effective);
        item.expires = mixins.methods.utcToLocal(item.expires);
        return item;
    });

    state.table.rows = rows;
    state.table.pagination = mixins.methods.formatPagination({count, page, perPage});
};

const setSearch = (state, payload) => {
    state.table.search = { ...state.table.search, ...payload };
};

const resetSearch = (state, payload) => {
    state.table.search = {};
};

const showEdit = (state, payload) => {
    payload = JSON.parse(JSON.stringify(payload));
    payload.premium = mixins.methods.toMoney(payload.premium);
    payload.oldPremium = mixins.methods.toMoney(payload.oldPremium);

    state.modal.details = { ...state.modal.details, ...payload };
    state.modal.component = 'Edit';
};

const showAdd = (state, payload) => {
    state.modal.details = { ...state.modal.details, ...payload };
    state.modal.component = 'Add';
};

const showDelete = (state, payload) => {
    payload.premium = mixins.methods.toMoney(payload.premium);
    payload.oldPremium = mixins.methods.toMoney(payload.oldPremium);

    state.modal.details = { ...state.modal.details, ...payload };
    state.modal.component = 'Delete';
};

const hideModal = (state, payload) => {
    state.modal.details = index.state().modal.details;
    state.modal.component = '';
};

const showCustomerEdit = (state) => {
    state.modal.details = index.state().modal.details;
    state.modal.component = 'CustomerEdit';
};

const setDetails = (state, payload) => {
    state.modal.details = { ...state.modal.details, ...payload };
};

const setTypes = (state, payload) => {
    state.types = payload;
}

const setCarrierList = (state, payload) => {
  state.carrierList = payload
};

export default {
    setTable,
    setSearch,
    resetSearch,
    setDetails,
    showEdit,
    showDelete,
    hideModal,
    showCustomerEdit,
    showAdd,
    setTypes,
    setCarrierList
};
