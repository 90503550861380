<template>
    <Preloader />
    <router-view :key="$route.fullPath"/>
</template>
<script>
import Preloader from './components/Preloader';
export default {
    components: { Preloader },
};
</script>
<style lang="scss">
html,
body {
    min-height: 100%;
}
#app {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    height: 100vh;
    min-width: 500px;
}

.max-height-1000px {
    .options {
      max-height: 1000px !important;
    }
}

.table-container {
    position: relative;
    table tr th:first-child {
    box-shadow: 37px 20px 16px -17px rgba(0, 0, 0, 0.04) inset;
    -webkit-box-shadow: 37px 20px 16px -17px rgba(0, 0, 0, 0.04) inset;
    -moz-box-shadow: 37px 20px 16px -17px rgba(0, 0, 0, 0.04) inset;
}
}

.table-section,
.modal,
.search-bar {
    .input-container:not(.date) {
        input[type='number'],
        input[type='text'],
        input,
        textarea {
            &:disabled {
                border-color: transparent !important;
                background:  rgb(232,232,232);
            }
            &:read-only {
                border-color: transparent !important;
                background:  rgb(232,232,232);
                &:focus {
                    background: rgb(232, 232, 232);
                }
            }
            padding-left: 13.4px !important;
            padding-right: 13.4px !important;
            border-width: 1px;
            &:focus {
                padding-left: 12.4px !important;
                padding-right: 12.4px !important;
                border-width: 2px !important;
            }
        }
    }
}

section.table-section {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: auto;
    overflow-y: overlay;
    .highlight {
        color: rgba(4, 127, 255, 1);
        font-weight: 500;
        &:hover {
            text-decoration-line: underline;
        }
        &:active {
           text-decoration-line: underline;
           color: #0469D2;
        }
    }

    table {
        width: 100%;
        text-align: left;
        td,
        th {
            height: 39.59px;
            padding-left: 15px;
            padding-right: 15px;        
        }

        td div {
            color: rgba(39, 51, 67, 1);
        }

        th {
            color: white;
            background: theme('colors.blue.500');
            position: sticky;
            top: 0;
            // z-index: 99;
            white-space: nowrap;
        }
        thead {
            tr {
                background: rgba(4, 127, 255, 1);
            }
        }

        td:last-child,
        th:last-child {
            padding-left: 10px;
            padding-right: 20px;
        }

        tbody {
            tr {
                background: white;

                &:nth-child(odd) {
                    background: theme('colors.gray.50');
                }
                &:nth-child(even) {
                    background: white;
                }

                td {
                    font-size: 0.8rem;
                    div.table-notes {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 400px;
                        min-width: 100px;
                    }
                    color: theme('colors.blue.800');
                }
                &:hover {
                    background: #e1f6ff;
                }
            }
        }
        .table-icons {
            padding-left: 7.95px;
            svg,
            img {
                width: 22px;
                height: 22px;
                border-radius: 50%;
                margin: 3px;
            }
            display: flex;
            a {
                border: 1px solid transparent;
                border-radius: 50%;
            }
            a.note {
                &:hover {
                    background: rgba(82, 177, 92, 0.19);
                }
                &:active {
                    background: rgba(82, 177, 92, 0.19);
                    border: 0.5px solid #d2d2d2;
                }
            }

            a.file {
                &:hover {
                    background: rgba(255, 118, 74, 0.19);
                }
                &:active {
                    background: rgba(255, 118, 74, 0.19);
                    border: 0.5px solid #d2d2d2;
                }
            }

            a.edit {
                &:hover {
                    background: rgba(4, 127, 255, 0.19);
                }
                &:active {
                    background: rgba(4, 127, 255, 0.19);
                    border: 0.5px solid #d2d2d2;
                }
            }

            a.delete {
                &:hover {
                    background: rgba(159, 159, 159, 0.19);
                }
                &:active {
                    background: rgba(159, 159, 159, 0.19);
                    border: 0.5px solid #d2d2d2;
                }
            }

            a.add {
                &:hover {
                    background: rgba(164, 82, 177, 0.19);
                }
                &:active {
                    background: rgba(164, 82, 177, 0.19);
                    border: 0.5px solid #d2d2d2;
                }
            }

            a.cancel {
                &:hover {
                    background: rgba(159, 159, 159, 0.19);
                }
                &:active {
                    background: rgba(159, 159, 159, 0.19);
                    border: 0.5px solid #d2d2d2;
                }
            }

            a.renew {
                &:hover {
                    background: rgba(159, 159, 159, 0.19);
                }
                &:active {
                    background: rgba(159, 159, 159, 0.19);
                    border: 0.5px solid #d2d2d2;
                }
            }
        }
    }
}

// Search Bar
section.search-bar {
    color: white;
    text-align: center;
    padding-top: 21.83px;
    column-gap: 12.92px;
    flex-wrap: wrap;
    row-gap: 12.92px;
    padding-left: 19.92px;
    padding-right: 19.92px;
    padding-bottom: 22px;

    .input-container {
        position: relative;
        text-align: left;
        color: rgba(62, 62, 62, 1);
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        label {
            line-height: 18px;
            font-weight: 700;
             font-size: 14px;
            white-space: nowrap;
            padding-bottom: 3.4px;
        }
    }

    input {
        height: 41.18px;
        font-size: 14px;
        width: 100%;
        min-width: auto;
        color: rgba(62, 62, 62, 1);
        padding: 0 5px;
        background: white;
        border-radius: 4px;
        border: 1px solid #878787;
        background: #f2f2f2;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box; /* Firefox, other Gecko */
        box-sizing: border-box;
        &::placeholder {
            color: rgba(196, 196, 196, 1);
        }
        &:focus {
            border: 2px solid #047fff;
            padding: 0 4px;
            background: #fff;
        }
    }

    input[type='date']::-webkit-calendar-picker-indicator {
        margin-left: -15px;
    }

    input[type='date'],
    input[type='date']::-webkit-input-placeholder {
        font-size: 14px;
    }

    /* placeholder text style */
    input[type='date']::-webkit-datetime-edit-text,
    input[type='date']::-webkit-datetime-edit-month-field,
    input[type='date']::-webkit-datetime-edit-day-field,
    input[type='date']::-webkit-datetime-edit-year-field {
        color: rgba(196, 196, 196, 1);
    }

    /* regular text style */
    input[type='date'].date-has-value::-webkit-datetime-edit-text,
    input[type='date'].date-has-value::-webkit-datetime-edit-month-field,
    input[type='date'].date-has-value::-webkit-datetime-edit-day-field,
    input[type='date'].date-has-value::-webkit-datetime-edit-year-field {
        color: rgba(62, 62, 62, 1);
    }

    button {
        width: 100%;
        font-size: 14px;
        height: 41.18px;
        border-radius: 4px;
        border: 1px solid transparent;
        &.reset {
            color: rgba(49, 49, 49, 1);
            background: rgba(238, 238, 238, 1);
            &:hover,
            &:active {
                background: rgba(208, 208, 208, 1);
            }
            &:active {
                border-color: #adadad;
            }
        }
        &.search {
            color: white;
            background: theme('colors.blue.500');
            &:hover,
            &:active {
                background: theme('colors.blue.570');
            }
            &:active {
                border-color: #7abbff;
            }
        }
    }

    /* Chrome, Safari, Edge, Opera */
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
}

// Modals
section.modal {
    position: absolute;
    inset: 0 0 0 0;
    background: rgba(0, 0, 0, 0.66);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    .container {
        border-radius: 11px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 739.05px;
        background: white;
        .header {
            height: 52.6px;
            border-top-left-radius: 11px;
            border-top-right-radius: 11px;
            background: rgba(4, 127, 255, 1);
            position: relative;
            .x-button {
                svg {
                    width: 19px;
                    height: 19px;
                }
                padding: 8px;
                position: absolute;
                right: 16.82px;
                border-radius: 50%;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                    background: rgba(255, 255, 255, 0.23);
                }
                &:active {
                    background: rgba(255, 255, 255, 0.47);
                }
            }
            h4 {
                font-size: 24px;
                padding-top: 3px;
            }
        }
        
        .content {
            width: 100%;
            padding: 30.03px 34.13px 43.6px 34.13px;
        }

        .buttons {
            column-gap: 18.6px;
        }
    }

    .container.delete {
        .header {
            background: rgba(255, 129, 114, 1);
        }

        .buttons {
            button.save {
                color: white;
                background: rgba(255, 129, 114, 1);
                span {
                    display: none;
                }
                &:after {
                    content: 'Delete';
                }
                &:hover {
                    background: rgba(255, 88, 68, 1);
                }
                &:active {
                    background: rgba(255, 59, 36, 1);
                }
            }
           

        }
        .input-container {
          &:not(.date) {
            input,
            textarea {
                background: rgba(232, 232, 232, 1);
            }
           }

            input,
            textarea {
                border-color: transparent;
            }
            input {
                pointer-events: none;
            }
        }
      
            button, a {
                pointer-events: auto;
            }
       
    }

    .input-container {
        width: 100%;
        position: relative;
        text-align: left;
        color: rgba(62, 62, 62, 1);
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        label {
            line-height: 18px;
             font-size: 14px;
            font-weight: 700 !important;
            padding-bottom: 3.4px;
        }
        .radio-box-container {
            height: 41.18px;
            display: flex;
            column-gap: 15px;
            font-size: 14px;
            .radio-box {
                height: 41.18px;
                display: flex;
                align-items: center;
            }
            input {
                margin-right: 10px;
                width: 15px;
                height: 15px;
            }
        }


    input,
    textarea {
        height: 41.18px;
        font-size: 14px;
        width: 100%;
        min-width: auto;
        color: rgba(62, 62, 62, 1);
        padding: 0 5px;
        background: white;
        border-radius: 4px;
        border: 1px solid #878787;
        background: #f2f2f2;
        &::placeholder {
            color: rgba(196, 196, 196, 1);
        }
        &:focus {
            border: 2px solid #047fff;
            padding: 0 4px;
            background: #fff;
        }
    }
    textarea {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        resize: none;
        &:focus {
           padding-top: 9px !important;
           padding-bottom: 9px !important;
        }
    }
    }


    button {
        width: 100%;
        font-size: 14px;
        border-radius: 4px;
        border: 1px solid transparent;
        height: 50px;
        width: 204px;
        font-size: 14px;
        &.cancel {
            color: rgba(49, 49, 49, 1);
            background: rgba(223, 223, 223, 1);
            &:hover,
            &:active {
                background: rgba(208, 208, 208, 1);
            }
            &:active {
                border-color: #adadad;
            }
        }
        &.save {
            color: white;
            background: theme('colors.blue.500');
            &:hover,
            &:active {
                background: theme('colors.blue.570');
            }
            &:active {
                border-color: #7abbff;
            }
        }
    }
}


.pagination {
    position: relative;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 21px;
    height: 54.23px;
    background: #F8F8F8;
    align-items: center;
    *  {
         font-size: 12px !important;
    }
}


// Modify scrollbar
::-webkit-scrollbar {
    width: 12.88px;
    height: 12.88px;
    border-radius: 27px;
    cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(229, 229, 229, 1);
    cursor: pointer;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 27px;
    min-height: 40px;
    cursor: pointer ;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #989898;
     cursor: pointer;
}

:disabled {
    pointer-events: none;
}


*:focus {
    outline: none !important;
}

textarea {   
    cursor: auto;
}
</style>
