const getTablePagination = (state) => {
  return state.table.pagination;
};

const getTableRows = (state) => {
  return state.table.rows;
};

const getSearch = (state) => {
  return state.table.search;
};

const getTypes = (state) => (modal)=>  {
  if(!modal || !modal.customerId) {
    return  ['', ...state.types.business, ...state.types.personal];
  }
  if(modal.company) {
    return state.types.business
  } else {
    return state.types.personal
  }
};

export default {
  getTablePagination,
  getTableRows,
  getSearch,
  getTypes
};
