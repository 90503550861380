import dayjs from 'dayjs';

import mixins from '@/mixins';
import index from './index';

const setTable = (state, { rows, count, page, perPage }) => {
    rows = rows.map((item) => {
        item.effective = mixins.methods.utcToLocal(item.effective);
        item.expires = mixins.methods.utcToLocal(item.expires);
        item.daysAway = mixins.methods.daysDiffAllowNegative(item.expires, dayjs().local().format('M/D/YYYY'));
        return item;
    });

    state.table.rows = rows;
    state.table.pagination = mixins.methods.formatPagination({count, page, perPage});
};

const setSearch = (state, payload) => {
    state.table.search = { ...state.table.search, ...payload };
};

const resetSearch = (state, payload) => {
    state.table.search = {};
};

const hideModal = (state, payload) => {
     state.modal.details = index.state().modal.details;
     state.modal.component = '';
};

const showCustomerEdit = (state) => {
      state.modal.details = index.state().modal.details;
     state.modal.component = 'CustomerEdit';
};

const setDetails = (state, payload) => {
    state.modal.details = { ...state.modal.details, ...payload };
};

const setRenewDetails = (state, payload) => {
    state.modal.details.renew = { ...state.modal.details.renew, ...payload };

}

const showTerminate = (state, payload) => {
    payload.premium = mixins.methods.toMoney(payload.premium);

    state.modal.details = { ...state.modal.details, ...payload };
    state.modal.component = 'Terminate';
};

const showRenew = (state, payload) => {
    payload = JSON.parse(JSON.stringify(payload));

    payload.premium = mixins.methods.toMoney(payload.premium);
    state.modal.details = { ...state.modal.details, ...payload };

    // Calculate months between
    const monthsDiff = mixins.methods.monthsDiff(payload.effective, payload.expires)
   
    const newEffective = payload.expires;
    const newExpires = mixins.methods.addDuration(newEffective, monthsDiff, 'months') 

    setRenewDetails(state, {effective: newEffective, expires: newExpires, notes: payload.notes, commissionRate: payload.commissionRate});
    state.modal.component = 'Renew';
};

export default {
    setTable,
    setSearch,
    resetSearch,
    setDetails,
    hideModal,
    showRenew,
    showTerminate,
    showCustomerEdit,
    setRenewDetails
};
