import axios from 'axios';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import calendar from 'dayjs/plugin/calendar';

import mixins from '@/mixins';
dayjs.extend(calendar);
dayjs.extend(dayOfYear);

const fetchTableRows = ({ commit, dispatch }, payload) => {
    return new Promise(async (resolve, reject) => {
        try {
            await mixins.methods.setQueryParams(payload || {});
            const perpage = localStorage.getItem('performance_per_page') || 50;

            let params = new URLSearchParams(location.search);
            const since = (params.get('since') || '').toLowerCase();

            for (const key of ['expiresFrom', 'expiresTo', 'effectiveFrom', 'effectiveTo', 'cancelledFrom', 'cancelledTo']) {
                let val = mixins.methods.addtimezone(params.get(key));
                if (val) {
                    params.set(key, val);
                } else {
                    params.delete(key);
                }
            }

            for (const key of ['premiumFrom', 'premiumTo', 'adjustedFrom', 'adjustedTo']) {
                let val = mixins.methods.moneyToNo(params.get(key));
                if (val) {
                    params.set(key, val);
                } else {
                    params.delete(key);
                }
            }

            params.set('size', perpage);
            params.set('sort', 'effective');
            params = params.toString();

            mixins.methods.loader();

            let { data } = await axios.get(`/xhr/dashboard/performance?${params}`);
            data.perPage = perpage;
          
            commit('setTable', data);

            mixins.methods.loader(false);
            resolve(true);
        } catch (err) {
            console.log(err);
            reject(dispatch('toast/showToast', null, { root: true }));
        }
    });
};

export default {
    fetchTableRows,
};
