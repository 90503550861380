import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
    namespaced: true,
    state: () => ({
        table: {
            rows: [],
            pagination: null,
            search: {},
        },
        modal: {
            details: {
                customerId: null,
                fullName: null,
                company: null,
                effective: null,
                expires: null,
                carrier: null,
                type: null,
                notes: null,
                commissionRate: null,
                renew: {
                  premium: null,
                  effective: null,
                  expires: null
              },
            },

            component: null,
        },
    }),
    actions,
    getters,
    mutations,
};
