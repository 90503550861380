import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: () => ({
    table: {
      rows: [],
      pagination: null,
      search: {},
    },
    tableScroll: null,
    customerList: [],
    activeRowId: null,
    modal: {
      details: {
        notes: null,
        firstName: null,
        lastName: null,
        company: null,
        email: null,
        phone: null,
      },
      component: null
    }
  }),
  actions,
  getters,
  mutations,
};
