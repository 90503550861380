import axios from 'axios';
import mixins from '@/mixins';

const fetchTableRows = ({ commit, dispatch }, payload) => {
    return new Promise(async (resolve, reject) => {
        try {
            await mixins.methods.setQueryParams(payload || {});
            const perpage = localStorage.getItem('renewals_per_page') || 50;

            let params = new URLSearchParams(location.search);
            const expiresFrom = params.get('expiresFrom');
            const expiresTo = params.get('expiresTo');

            if (expiresFrom) {
                params.set('expiresFrom', mixins.methods.addtimezone(expiresFrom));
            }

            if (expiresTo) {
                params.set('expiresTo', mixins.methods.addtimezone(expiresTo));
            }

            params.set('size', perpage);
            params.set('renewed', null);
            params.set('sort', 'expires');
            params = params.toString();

            mixins.methods.loader();

            const { data } = await axios.get(`/xhr/dashboard/policies?${params}`);
            data.perPage = perpage;
            commit('setTable', data);

            mixins.methods.loader(false);
            resolve(true);
        } catch (err) {
            reject(dispatch('toast/showToast', null, { root: true }));
        }
    });
};

const renew = ({ state, dispatch }) => {
    return new Promise(async (resolve, reject) => {
        try {
            state = state.modal.details;
            const payload = {
                effective: mixins.methods.addtimezone(state.renew.effective),
                expires: mixins.methods.addtimezone(state.renew.expires),
                premium: mixins.methods.moneyToNo(state.renew.premium),
                notes: state.renew.notes,
                commissionRate: state.renew.commissionRate
            };

            await axios.post(`/xhr/dashboard/policies/renew/${state.id}`, payload);
            resolve(true);
        } catch (err) {
            reject(dispatch('toast/showToast', null, { root: true }));
        }
    });
};

const terminate = ({ state, dispatch }) => {
    return new Promise(async (resolve, reject) => {
        try {
            const policyId = state.modal.details.id;
            await axios.put(`/xhr/dashboard/policies/${policyId}`, { renewed: false });
            resolve(true);
        } catch (err) {
            reject(dispatch('toast/showToast', null, { root: true }));
        }
    });
};

export default {
    fetchTableRows,
    renew,
    terminate,
};
