import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
    namespaced: true,
    state: () => ({
        table: {
            rows: [],
            pagination: null,
            search: {},
        },
        modal: {
            details: {
                newBusiness: null,
                renewed: null,
                effective: null,
                expires: null,
                cancelled: null,
                carrier: null,
                type: null,
                notes: null,
                links: null,
                commissionRate: null,
                oldPremium: null,
                premium: null,
                customerId: null,
                address:null,
                company:null,
                fullName:null,
            },
            component: null,
        },
        carrierList: [],

        types: {
            personal: [],
            business: []
        }
    }),
    actions,
    getters,
    mutations,
};
