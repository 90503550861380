import axios from 'axios';
import { createApp } from 'vue';

import App from './App.vue';
import store from './store';
import router from './router';
import mixins from './mixins';
import './main.css';

axios.defaults.withCredentials = true;
axios.defaults.validateStatus = null;

const app = createApp(App);

app.use(router);
app.use(store);
app.mixin(mixins);

axios.interceptors.response.use(
    (res) => {
        if (res.config.url.includes('/xhr/dashboard/') && res.status !== 200) {
            // forward err message to axios[request].catch
            try {
                mixins.methods.loader(false);
            } catch (_) {
                // ignore error
            }
            throw res;
        }
        return res;
    },
    (err) => {
        if (err.toString() == 'Error: Network Error') {
            // update err status from null to 404
            err.status = 404;
        }
        try {
            mixins.methods.loader(false);
        } catch (_) {
            // ignore error
        }
        // forward err message to axios[request].catch
        return Promise.reject(err);
    },
);

app.directive('click-outside', {
    inserted: function (el, binding, vnode) {
        el.dataset.guard = false;
    },
    mounted(el) {
        setTimeout(() => {
            el.dataset.guard = true;
        }, 500);
    },
    beforeMount(el, binding) {
        el.clickOutsideEvent = function (event) {
            if (!el.dataset.guard) {
                return;
            }
            if (!(el == event.target || el.contains(event.target))) {
                binding.value(event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    // unbind
    unmounted(el) {
        el.dataset.guard = false;
        document.body.removeEventListener('click', el.clickOutsideEvent);
    },
});

app.directive('esc', {
    beforeMount(el, binding) {
        el._keydownCallback = (event) => {
            if (event.key === 'Escape') {
                binding.value();
            }
        };
        document.addEventListener('keydown', el._keydownCallback);
    },
    unmounted(el, binding) {
        document.removeEventListener('keydown', el._keydownCallback);
        delete el._keydownCallback;
    },
});

router.isReady().then(() => {
    app.mount('#app', true);
});
