import { createStore, createLogger } from 'vuex';

import customers from './modules/customers';
import policies from './modules/policies';
import renewals from './modules/renewals';
import performance from './modules/performance';
import toast from './modules/toast';

export default createStore({
    modules: {
        customers,
        policies,
        renewals,
        performance,
        toast,
    },

    state: () => ({
        spread: localStorage.getItem('spread-sidebar'),
    }),

    actions: {
        hideModals({ commit }) {
            commit('customers/hideModal', null, { root: true });
            commit('policies/hideModal', null, { root: true });
            commit('renewals/hideModal', null, { root: true });
            commit('performance/hideModal', null, { root: true });
        },
    },

    getters: {
        getSpreadSidebar(state) {
            let spread = state.spread === 'false' ? false : true;
            return spread;
        },
    },

    mutations: {
        toggleSidebar(state, payload) {
            localStorage.setItem('spread-sidebar', payload);
            state.spread = payload.toString();
        },
    },

    plugins: process.env.NODE_ENV !== 'production' ? [createLogger()] : [],
});
