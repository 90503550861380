import { createRouter, createWebHistory } from 'vue-router';

import Container from './App';

const routes = [
    {
        path: '/auth',
        component: Container,
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import('./pages/auth/login/Index.vue'),
            },
            {
                path: 'two-factor',
                name: 'two-factor',
                component: () => import('./pages/auth/twoFactor/Index.vue'),
            },
            {
                path: 'verify',
                name: 'verify',
                component: () => import('./pages/auth/twoFactor/Index.vue'),
            },
            {
                path: 'forgot-password',
                name: 'forgot-password',
                component: () => import('./pages/auth/forgotPassword/Index.vue'),
            },
            {
                path: 'create-account',
                name: 'create-account',
                component: () => import('./pages/auth/createAccount/Index.vue'),
            },
            {
                path: 'set-password',
                name: 'set-password',
                component: () => import('./pages/auth/setPassword/Index.vue'),
            },
        ],
    },
    {
        path: '/dashboard',
        redirect: '/dashboard/customers',
        component: Container,
        children: [
            {
                path: 'policies',
                name: 'policies',
                component: () => import('./pages/policies/Index.vue'),
            },
            {
                path: 'customers',
                name: 'customers',
                component: () => import('./pages/customers/Index.vue'),
            },
            {
                path: 'renewals',
                name: 'renewals',
                component: () => import('./pages/renewals/Index.vue'),
            },
             {
                path: 'performance',
                name: 'performance',
                component: () => import('./pages/performance/Index.vue'),
            },
        ],
    },
    {  path: "/:catchAll(.*)",   redirect: '/dashboard'}
    
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
