import axios from 'axios';
import mixins from '@/mixins';

const fetchTableRows = ({ commit, dispatch }, payload) => {
    return new Promise(async (resolve, reject) => {
        try {
            await mixins.methods.setQueryParams(payload || {});
            const perpage = localStorage.getItem('policies_per_page') || 50;

            let params = new URLSearchParams(location.search);
            params.set('size', perpage);

            for (const key of ['effectiveFrom', 'effectiveTo']) {
                let val = mixins.methods.addtimezone(params.get(key));
                if (val) {
                    params.set(key, val);
                } else {
                    params.delete(key);
                }
            }
            
            params.set('sort', '-effective');
            params = params.toString();

            mixins.methods.loader();

            const { data } = await axios.get(`/xhr/dashboard/policies?${params}`);
            data.perPage = perpage;
            commit('setTable', data);

            mixins.methods.loader(false);
            resolve(true);
        } catch (err) {
            reject(dispatch('toast/showToast', null, { root: true }));
        }
    });
};

const createTableRow = ({ dispatch }, payload) => {
    return new Promise(async (resolve, reject) => {
        try {
            payload = JSON.parse(JSON.stringify(payload));
            payload.effective = mixins.methods.addtimezone(payload.effective);
            payload.expires = mixins.methods.addtimezone(payload.expires);
            payload.premium = mixins.methods.moneyToNo(payload.premium);
            payload.oldPremium = mixins.methods.moneyToNo(payload.oldPremium);

            delete payload.fullName;
            delete payload.company;
            delete payload.address;

            const { data } = await axios.post('/xhr/dashboard/policies', payload);

            // Fetch updated types
            dispatch('types');
            
            resolve(data);
        } catch (err) {
            reject(dispatch('toast/showToast', null, { root: true }));
        }
    });
};

const updateTableRow = ({ dispatch }, payload) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Always clone object whenever we mutate date and money or etc. Otherwise, its new value will also take change to other inputs
            payload = JSON.parse(JSON.stringify(payload));
            const id = payload.id;

            payload.cancelled = mixins.methods.addtimezone(payload.cancelled);
            payload.effective = mixins.methods.addtimezone(payload.effective);
            payload.expires = mixins.methods.addtimezone(payload.expires);
            payload.premium = mixins.methods.moneyToNo(payload.premium);
            payload.oldPremium = mixins.methods.moneyToNo(payload.oldPremium);

            await axios.put(`/xhr/dashboard/policies/${id}`, payload);
            // Fetch updated types
            dispatch('types');

            resolve(true);
        } catch (err) {
            reject(dispatch('toast/showToast', null, { root: true }));
        }
    });
};

const deleteTableRow = ({ dispatch }, { id }) => {
    return new Promise(async (resolve, reject) => {
        try {
            await axios.delete(`/xhr/dashboard/policies/${id}`);
            resolve(true);
        } catch (err) {
            reject(dispatch('toast/showToast', null, { root: true }));
        }
    });
};

const count = ({ dispatch }, { customerId }) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await axios.get(`/xhr/dashboard/policies/count/${customerId}`);
            resolve(data);
        } catch (err) {
            reject(dispatch('toast/showToast', null, { root: true }));
        }
    });
};

const types = ({ dispatch, commit }) => {
     return new Promise(async (resolve, reject) => {
        try {
            const { data } = await axios.get('/xhr/dashboard/types');
            commit('setTypes', data);
            resolve();
        } catch (err) {
            reject(dispatch('toast/showToast', null, { root: true }));
        }
    });
}

const carrierList = ({ commit, dispatch }, {carrier = ''}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await axios.get(`/xhr/dashboard/carriers?carrier=${carrier}`);
            commit('setCarrierList', data);
            resolve(data.length > 0);
        } catch (err) {
            reject(dispatch('toast/showToast', null, { root: true }));
        }
    });
};

export default {
    fetchTableRows,
    count,
    types,
    createTableRow,
    updateTableRow,
    deleteTableRow,
    carrierList
};
