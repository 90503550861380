export default {
    namespaced: true,
    state: () => ({
        rows: []
    }),
    actions: {
        showToast({commit}, payload) {
            commit('setToast', payload || '')
        }
    },
    mutations: {
      setToast(state, payload) {
        state.rows.push({msg: payload});
      }
    },
};


  