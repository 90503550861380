const getTablePagination = (state) => {
  return state.table.pagination;
};

const getTableRows = (state) => {
  return state.table.rows;
};

const getSearch = (state) => {
  return state.table.search;
};

export default {
  getTablePagination,
  getTableRows,
  getSearch,
};
